import { BaseService } from '@/core/base-service';
import { ITemplate } from '@/models/template.model';
import axios from 'axios';

export default class TemplateService extends BaseService<ITemplate> {
  public constructor() {
    super('services/servicescard/api/template');
  }

  public getDefaultTemplates() {
    return axios.get('services/servicescard/api/template/default-templates');
  }

  public getTemplatesForCombobox() {
    return axios.get(this.endpointName + '/combobox');
  }

  public publish(draftId: string, originalId: string) {
    return axios.post(this.endpointName + '/publish', { draftId: draftId, originalId: originalId });
  }

  public check(draftId: string, originalId: string) {
    return axios.post(this.endpointName + '/check', { draftId: draftId, originalId: originalId });
  }
}
