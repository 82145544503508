import axios from 'axios';
import { BaseService } from '@/core/base-service';

export default class CompanyService extends BaseService<any> {
  public constructor() {
    super('services/servicescard/api/admin/company');
  }

  public getCompanyByTenant(): Promise<any> {
    return axios.get('services/servicescard/api/admin/company/getCompanyByTenant');
  }

  public getCompanyByTenantId(id: string): Promise<any> {
    return axios.get(`services/servicescard/api/public/company/get-by-tenant/${id}`);
  }

  public updateById(object: any): Promise<any> {
    return axios.put(`services/servicescard/api/admin/company/${object.id}`, object);
  }

  public createQrCode(): Promise<any> {
    return axios.get(`services/servicescard/api/admin/company/create-qr-code`);
  }
}
