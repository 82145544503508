import { BaseService } from '@/core/base-service';
import axios from 'axios';
import generateQueryOptions from '@/shared/sort/sorts';

export default class ProgramSettingPublicService extends BaseService<any> {
  public constructor() {
    super('services/servicescard/api/public/setting');
  }

  public getSetting(tenantId: string, settingsKey: string, userId: string): Promise<any> {
    const queryParam = {
      filterParam: '?userId=' + (userId ? userId : ''),
    };
    return axios.get(this.endpointName + `/${tenantId}/${settingsKey}` + generateQueryOptions(queryParam));
  }
}
