import { Authority } from '@/shared/model/authority';

const TemplateBackComponent = () => import('@/project/template/step-back/template-back.vue');
const TemplateFrontComponent = () => import('@/project/template/step-front/template-front.vue');
const TemplateGeneralComponent = () => import('@/project/template/step-general/template-general.vue');
const TemplateLocationComponent = () => import('@/project/template/step-location/template-location.vue');
const TemplateTypeComponent = () => import('@/project/template/step-type/template-type.vue');

export default [
  {
    path: ':id?',
    name: 'TemplateStepType',
    component: TemplateTypeComponent,
    meta: {
      authorities: [Authority.SUPERADMIN, Authority.ADMIN],
    },
  },
  {
    path: '/template/wizard/general/:id?',
    name: 'TemplateStepGeneral',
    component: TemplateGeneralComponent,
    meta: {
      authorities: [Authority.SUPERADMIN, Authority.ADMIN],
    },
  },
  {
    path: '/template/wizard/front/:id?',
    name: 'TemplateStepFront',
    component: TemplateFrontComponent,
    meta: {
      authorities: [Authority.SUPERADMIN, Authority.ADMIN],
    },
  },
  {
    path: '/template/wizard/back/:id?',
    name: 'TemplateStepBack',
    component: TemplateBackComponent,
    meta: {
      authorities: [Authority.SUPERADMIN, Authority.ADMIN],
    },
  },
  {
    path: '/template/wizard/location/:id?',
    name: 'TemplateStepLocation',
    component: TemplateLocationComponent,
    meta: {
      authorities: [Authority.SUPERADMIN, Authority.ADMIN],
    },
  },
];

export const templateviewsteps = [
  {
    path: ':id?',
    name: 'TemplateViewStepType',
    component: TemplateTypeComponent,
    meta: {
      authorities: [Authority.SUPERADMIN, Authority.ADMIN, Authority.USER],
    },
  },
  {
    path: '/template/view/general/:id?',
    name: 'TemplateViewStepGeneral',
    component: TemplateGeneralComponent,
    meta: {
      authorities: [Authority.SUPERADMIN, Authority.ADMIN, Authority.USER],
    },
  },
  {
    path: '/template/view/front/:id?',
    name: 'TemplateViewStepFront',
    component: TemplateFrontComponent,
    meta: {
      authorities: [Authority.SUPERADMIN, Authority.ADMIN, Authority.USER],
    },
  },
  {
    path: '/template/view/back/:id?',
    name: 'TemplateViewStepBack',
    component: TemplateBackComponent,
    meta: {
      authorities: [Authority.SUPERADMIN, Authority.ADMIN, Authority.USER],
    },
  },
  {
    path: '/template/view/location/:id?',
    name: 'TemplateViewStepLocation',
    component: TemplateLocationComponent,
    meta: {
      authorities: [Authority.SUPERADMIN, Authority.ADMIN, Authority.USER],
    },
  },
];
