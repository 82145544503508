import axios from 'axios';
import { BaseService } from '@/core/base-service';

export default class CompanySubscriptionService extends BaseService<any> {
  public constructor() {
    super('services/servicescard/api/company-subscription');
  }

  public retrieveActiveSubscription(): Promise<any> {
    return axios.get(this.endpointName + '/active');
  }
}
