import axios from 'axios';
import { BaseService } from '@/core/base-service';

export default class PaymentMethodService extends BaseService<any> {
  public constructor() {
    super('services/servicescard/api/admin/payment-method');
  }

  public activate(object: any): Promise<any> {
    return axios.post(`services/servicescard/api/admin/payment-method/activate`, object);
  }

  public updateById(object: any): Promise<any> {
    return axios.put(`services/servicescard/api/admin/payment-method/${object.id}`, object);
  }
}
