import axios from 'axios';
import dayjs from 'dayjs';

import primevueDe from '@i18n/de/primevue.json';
import primevueEn from '@i18n/en/primevue.json';

export default class TranslationService {
  public async refreshTranslation(i18n: any, primevue: any, currentLanguage: string) {
    if (!i18n.messages) {
      i18n.messages = [];
    }

    if (!i18n.messages[currentLanguage]) {
      await axios.get(`i18n/${currentLanguage}.json?_=${I18N_HASH}`).then((res: any) => {
        i18n.global.setLocaleMessage(currentLanguage, res.data);
      });
    }

    this.setLocale(currentLanguage, i18n);

    if (primevue?.config) {
      primevue.config.locale = currentLanguage === 'de' ? primevueDe : primevueEn;
    }
  }

  private setLocale(lang: string, i18n) {
    dayjs.locale(lang);
    axios.defaults.headers.common['Accept-Language'] = lang;
    i18n.global.locale.value = lang;
    document.querySelector('html').setAttribute('lang', lang);
  }
}
