import axios from 'axios';
import { BaseService } from '@/core/base-service';

export default class SubscriptionService extends BaseService<any> {
  public constructor() {
    super('services/servicescard/api/subscription');
  }

  public getSubscriptions(): Promise<any> {
    return axios.get(this.endpointName + '?sort=amount,ASC');
  }
}
