import axios from 'axios';
import { BaseService } from '@/core/base-service';

export default class PaymentService extends BaseService<any> {
  public constructor() {
    super('services/servicescard/api/payment');
  }

  public getPayment(customerId: string): Promise<any> {
    return axios.get(this.endpointName);
  }

  public setPayment(paymentObject: object): Promise<any> {
    return axios.post(this.endpointName, { paymentObject });
  }
}
