import { Authority } from '@/shared/model/authority';

import templatesteps from '@/router/steps/templatesteps';
import { templateviewsteps } from '@/router/steps/templatesteps';

const BillingComponent = () => import('@/project/billing/billing.vue');
const CompanyComponent = () => import('@/project/company/company.vue');
const CustomerComponent = () => import('@/project/customer/customer-management.vue');
const CustomerDetailsComponent = () => import('@/project/customer/customer-details.vue');
const CustomerRegistrationComponent = () => import('@/project/customer-registration/customer-registration.vue');
const DashboardComponent = () => import('@/project/dashboard/dashboard.vue');
const ExampleComponent = () => import('@/project/example-component/example-component.vue');
const SaleScanComponent = () => import('@/project/sale/sale-scan-component.vue');
const NotificationComponent = () => import('@/project/notification/notification-management.vue');
const NotificationScheduledComponent = () => import('@/project/notification/notification-scheduled-management.vue');
const NotificationSendComponent = () => import('@/project/notification/notification-send-management.vue');
const PassInfoComponent = () => import('@/project/pass-information/pass-information.vue');
const PassListComponent = () => import('@/project/pass-list/pass-list.vue');
const PaymentMethodComponent = () => import('@/project/payment-method/payment-method.vue');
const ProgramSettingsComponent = () => import('@/project/program-setting/program-setting.vue');
const StatisticsComponent = () => import('@/project/statistics/statistics.vue');
const SubscriptionComponent = () => import('@/project/subscription/subscription.vue');
const TemplateComponent = () => import('@/project/template/template-management.vue');
const TemplateWizardComponent = () => import('@/project/template/template-wizard.vue');

export default [
  {
    path: '/settings/company',
    name: 'Companies',
    component: CompanyComponent,
    meta: {
      authorities: [Authority.SUPERADMIN, Authority.ADMIN],
    },
  },
  {
    path: '/settings/payment-methods',
    name: 'PaymentMethod',
    component: PaymentMethodComponent,
    meta: {
      authorities: [Authority.SUPERADMIN, Authority.ADMIN],
    },
  },
  {
    path: '/settings/subscription',
    name: 'Subscriptions',
    component: SubscriptionComponent,
    meta: {
      authorities: [Authority.SUPERADMIN, Authority.ADMIN],
    },
  },
  {
    path: '/settings/program',
    name: 'Program settings',
    component: ProgramSettingsComponent,
    meta: {
      authorities: [Authority.SUPERADMIN, Authority.ADMIN, Authority.USER],
    },
  },
  {
    path: '/customers',
    name: 'Customers',
    component: CustomerComponent,
    meta: {
      authorities: [Authority.SUPERADMIN, Authority.ADMIN, Authority.USER],
    },
  },
  {
    path: '/pass-list',
    name: 'Pass list',
    component: PassListComponent,
    meta: {
      authorities: [Authority.ADMIN, Authority.USER],
    },
  },
  {
    path: '/customer-details/:id?',
    name: 'CustomerDetails',
    component: CustomerDetailsComponent,
    meta: {
      authorities: [Authority.SUPERADMIN, Authority.ADMIN, Authority.USER],
    },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardComponent,
    meta: {
      authorities: [Authority.SUPERADMIN, Authority.ADMIN, Authority.USER],
    },
  },
  {
    path: '/billing',
    name: 'Billing',
    component: BillingComponent,
    meta: {
      authorities: [Authority.SUPERADMIN],
    },
  },
  {
    path: '/template',
    name: 'Template',
    component: TemplateComponent,
    meta: {
      authorities: [Authority.SUPERADMIN, Authority.ADMIN, Authority.USER],
    },
  },
  {
    path: '/notification/:scheduledNotificationSentId?',
    name: 'Notification',
    component: NotificationComponent,
    meta: {
      authorities: [Authority.SUPERADMIN, Authority.ADMIN, Authority.USER],
    },
  },
  {
    path: '/notification-send',
    name: 'Notification send',
    component: NotificationSendComponent,
    meta: {
      authorities: [Authority.SUPERADMIN, Authority.ADMIN, Authority.USER],
    },
  },
  {
    path: '/notification-scheduled',
    name: 'Scheduled notifications',
    component: NotificationScheduledComponent,
    meta: {
      authorities: [Authority.SUPERADMIN, Authority.ADMIN, Authority.USER],
    },
  },
  {
    path: '/example-component',
    name: 'ExampleComponent',
    component: ExampleComponent,
  },
  {
    path: '/sale/scan/:mode',
    name: 'SaleScanComponent',
    component: SaleScanComponent,
    meta: {
      authorities: [Authority.SUPERADMIN, Authority.ADMIN, Authority.USER],
    },
  },
  {
    path: '/pass-information/:tenantId/:customerId',
    name: 'PassInfo',
    component: PassInfoComponent,
  },
  {
    path: '/registration/:tenantId',
    name: 'Registration',
    component: CustomerRegistrationComponent,
  },
  {
    path: '/sale-registration/:tenantId',
    name: 'SalesModeRegistration',
    component: CustomerRegistrationComponent,
    meta: {
      authorities: [Authority.SUPERADMIN, Authority.ADMIN, Authority.USER],
    },
  },
  {
    path: '/template/view',
    name: 'TemplateViewWizardComponent',
    component: TemplateWizardComponent,
    meta: {
      authorities: [Authority.SUPERADMIN, Authority.ADMIN, Authority.USER],
    },
    children: [...templateviewsteps],
  },
  {
    path: '/template/wizard',
    name: 'TemplateWizardComponent',
    component: TemplateWizardComponent,
    meta: {
      authorities: [Authority.SUPERADMIN, Authority.ADMIN],
    },
    children: [...templatesteps],
  },
  {
    path: '/statistics',
    name: 'Statistics',
    component: StatisticsComponent,
    meta: {
      authorities: [Authority.ADMIN],
    },
  },
];
