import axios from 'axios';
import { BaseService } from '@/core/base-service';

export default class PassNotificationService extends BaseService<any> {
  public constructor() {
    super('services/servicescard/api/pass-notification');
  }

  public send(customerId: string, passInformationIds: string[], message: any): Promise<any> {
    const body = {
      filterCustomerId: customerId,
      message: message,
      passInformationIds: passInformationIds,
    };
    return axios.post(this.endpointName + `/send-by-pass-information-ids`, body);
  }
}
