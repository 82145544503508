// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.common with an alias.
import App from './app.vue';
import VSwatches from 'vue3-swatches';
import Vue3TouchEvents from 'vue3-touch-events';
import mitt from 'mitt';
import { FilterService } from 'primevue/api';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { createApp, reactive, watch } from 'vue';
import { initPrimeVue } from '@/config/config-primevue';
import { initRouter } from '@/router';

import AccountService from '@/account/account.service';
import ChangePasswordService from '@/account/change-password/change-password.service';
import ChartService from '@/project/services/chart.service';
import CompanyService from '@/project/services/company.service';
import CompanySubscriptionService from '@/project/services/company-subscription.service';
import CompanySubscriptionMasterService from '@/project/services/company-subscription-master.service';
import ConfigurationService from '@/admin/configuration/configuration.service';
import CountryService from '@/project/services/country.service';
import CustomerService from '@/project/services/customer.service';
import DashboardConfigurationService from '@/project/services/dashboard-configuration.service';
import GatewayService from '@/admin/gateway/gateway.service';
import HealthService from '@/admin/health/health.service';
import LoginService from '@/account/login.service';
import LogsService from '@/admin/logs/logs.service';
import MetricsService from '@/admin/metrics/metrics.service';
import PassInformationNotificationSendListService from '@/project/services/pass-information-notification-send-list.service';
import PassInformationService from '@/project/services/pass-information.service';
import PassNotificationService from '@/project/services/pass-notification.service';
import PaymentMethodService from '@/project/services/payment-method.service';
import PaymentService from '@/project/services/payment.service';
import PaymentTypeService from '@/project/services/payment-type.service';
import ProgramSettingPublicService from '@/project/services/program-setting-public.service';
import ProgramSettingService from '@/project/services/program-setting.service';
import QrCodeService from '@/project/services/qr-code.service';
import ProviderService from '@/admin/user-management/provider.service';
import ScheduledNotificationService from '@/project/services/scheduled-notification.service';
import SchemaService from '@/admin/tenant-management/schema-service';
import StatisticsService from '@/project/services/statistics.service';
import SubscriptionService from '@/project/services/subscription.service';
import TemplateService from '@/project/services/template.service';
import TemplateTypeService from '@/project/services/template-type.service';
import TenantMasterService from '@/admin/tenant-management/tenant-master.service';
import ToastService from 'primevue/toastservice';
import TranslationService from '@/locale/translation.service';
import UserManagementService from '@/admin/user-management/user-management.service';
import VariableService from '@/project/services/variable.service';
import WizardService from '@/project/services/wizard.service';
import { setupAxiosInterceptors } from '@/shared/config/axios-interceptor';

import {
  AlertService,
  AlertServiceConfiguration,
  getGlobalProps,
  initFortAwesome,
  initI18N,
  initVueXStore,
  JhiSortIndicator,
} from '@rednex/gateway_core';
import { projectStore } from '@/project/store/project-store';

import '../content/scss/global.scss';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';
import '@rednex/gateway_core/componentstyles';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'primevue/resources/primevue.min.css';
import 'vue3-swatches/dist/style.css';

const emitter = mitt();
const globalProps = getGlobalProps();
const i18n = initI18N();
const router = initRouter();
const store = initVueXStore({ projectStore });

const translationService = new TranslationService();
const accountService = new AccountService(store, router);
const alertService = new AlertService(new AlertServiceConfiguration(false, 0, 5000, 5000, 10000));

router.beforeEach(async (to, from, next) => {
  const salesMode = store.getters.salesMode;
  const currentLanguage = store.getters.currentLanguage;
  await translationService.refreshTranslation(i18n, app.config.globalProperties?.$primevue, currentLanguage);

  if (!to.matched.length) {
    next('/not-found');
  } else if (to.meta && to.meta.authorities && (to.meta.authorities as []).length > 0) {
    await accountService
      .reloadAuthentication(to.meta.authorities)
      .then(authenticated => {
        if (!authenticated) {
          sessionStorage.setItem('requested-url', to.fullPath);

          if (to.fullPath !== '' && to.fullPath !== '/') {
            next('/forbidden');
          } else {
            next();
          }
        } else {
          if (salesMode && !to.fullPath.includes('/sale') && to.fullPath !== '' && to.fullPath !== '/') {
            const salesModeWarnEn = 'Are you sure that you want to leave this page? Sales mode will be disabled.';
            const salesModeWarnDe = 'Sind Sie sicher, dass Sie diese Seite verlassen möchten? Verkaufsmodus wird dadurch verlassen.';
            if (window.confirm(currentLanguage === 'de' ? salesModeWarnDe : salesModeWarnEn)) {
              store.commit('setSalesMode', false);
              next();
            } else {
              next(from.fullPath);
            }
          } else {
            next();
          }
        }
      })
      .catch(error => {
        alertService.showHttpError(globalProps, error);
      });
  } else {
    // no authorities, so just proceed
    next();
  }
});

/* tslint:disable */
export const app = createApp({
  el: '#app',
  components: { App } as any,
  template: '<App/>',
  provide: {
    accountService: accountService,
    alertService: alertService,
    changePasswordService: new ChangePasswordService(),
    chartService: new ChartService(),
    companyService: new CompanyService(),
    companySubscriptionService: new CompanySubscriptionService(),
    companySubscriptionMasterService: new CompanySubscriptionMasterService(),
    configurationService: new ConfigurationService(),
    countryService: new CountryService(),
    customerService: new CustomerService(),
    dashboardConfigurationService: new DashboardConfigurationService(),
    gatewayService: new GatewayService(),
    healthService: new HealthService(),
    loginService: new LoginService(),
    logsService: new LogsService(),
    metricsService: new MetricsService(),
    passInformationNotificationSendListService: new PassInformationNotificationSendListService(),
    passInformationService: new PassInformationService(),
    passNotificationService: new PassNotificationService(),
    paymentMethodService: new PaymentMethodService(),
    paymentService: new PaymentService(),
    paymentTypeService: new PaymentTypeService(),
    programSettingPublicService: new ProgramSettingPublicService(),
    programSettingService: new ProgramSettingService(),
    qrCodeService: new QrCodeService(),
    providerService: new ProviderService(),
    scheduledNotificationService: new ScheduledNotificationService(),
    schemaService: new SchemaService(),
    statisticsService: new StatisticsService(),
    subscriptionService: new SubscriptionService(),
    templateService: new TemplateService(),
    templateTypeService: new TemplateTypeService(),
    tenantMasterService: new TenantMasterService(),
    translationService: translationService,
    userManagementService: new UserManagementService(),
    variableService: new VariableService(),
    wizardService: new WizardService(''),
  },
});

app.config.globalProperties.emitter = emitter;
app.config.globalProperties.router = router;
app.config.globalProperties.$appState = reactive({ themeMode: 'light', salesMode: false });

app.use(ToastService);
app.use(VSwatches);
app.use(Vue3TouchEvents);
app.use(i18n);
app.use(router);
app.use(store);

app.component('font-awesome-icon', FontAwesomeIcon);
app.component('jhi-sort-indicator', JhiSortIndicator);

initFortAwesome();
initPrimeVue(app);

FilterService.register('DATEEQUALS', (value, filter): boolean => {
  if (filter === undefined || filter === null) {
    return true;
  }
  // test
  if (value === undefined || value === null) {
    return false;
  }

  return new Date(value).toLocaleDateString() === filter.toLocaleDateString();
});

FilterService.register('DATERANGE', (value, filter): boolean => {
  if (filter === undefined || filter === null) {
    return true;
  }
  // test
  if (value === undefined || value === null) {
    return false;
  }

  const startDate = new Date(filter[0]).toLocaleDateString();
  const dateToFilter = new Date(value).toLocaleDateString();

  if (!filter[1]) {
    return dateToFilter === startDate;
  } else {
    return dateToFilter >= startDate && dateToFilter <= new Date(filter[1]).toLocaleDateString();
  }
});

FilterService.register('BOOLEANBADGECHECK', (value, filterArray): boolean => {
  if (filterArray === undefined || filterArray === null || filterArray.length === 0) {
    return true;
  }

  const selected = Array.from(filterArray, data => {
    return (data as any).value.toString();
  });

  return selected.includes(value.toString());
});

setupAxiosInterceptors(
  unauthenticatedError => {
    const error = unauthenticatedError;
    const url = error.response?.config?.url;
    const status = error.status || error.response.status;
    if (status === 401) {
      // Store logged out state.
      if (!url.equals('/')) {
        store.commit('logout');
      }

      if (!url.endsWith('api/account') && !url.endsWith('api/authenticate')) {
        // Ask for a new authentication
        router.push('/');
        return;
      }
    }
    console.error('unauthenticatedError:', error);
    return Promise.reject(error);
  },
  badRequestError => {
    console.error('badRequestError:', badRequestError);
    return Promise.reject(badRequestError);
  },
  internalServerError => {
    console.error('internalServerError:', internalServerError);
    return Promise.reject(internalServerError);
  },
);

watch(
  () => {
    return store.getters.currentLanguage;
  },
  currentLanguage => {
    translationService.refreshTranslation(i18n, app.config.globalProperties?.$primevue, currentLanguage);
  },
);

app.mount('#app');
