import axios from 'axios';
import { BaseService } from '@/core/base-service';

export default class WizardService extends BaseService<any> {
  public getCompany(): Promise<any> {
    return axios.get('services/servicescard/api/wizard/company');
  }

  public getTemplate(): Promise<any> {
    return axios.get('services/servicescard/api/wizard/template');
  }

  public getCustomer(): Promise<any> {
    return axios.get('services/servicescard/api/wizard/customer');
  }

  public getPassInformation(): Promise<any> {
    return axios.get('services/servicescard/api/wizard/pass-information');
  }

  public getPassRegistration(): Promise<any> {
    return axios.get('services/servicescard/api/wizard/pass-registration');
  }

  public getPaymentMethod(): Promise<any> {
    return axios.get('services/servicescard/api/wizard/payment-method');
  }

  public getSubscription(): Promise<any> {
    return axios.get('services/servicescard/api/wizard/company-subscription');
  }
}
