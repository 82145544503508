import { BaseService } from '@/core/base-service';
import axios from 'axios';

export default class CustomerService extends BaseService<any> {
  public constructor() {
    super('services/servicescard/api/customer');
  }

  public updateById(object: any): Promise<any> {
    return axios.put(`services/servicescard/api/customer/${object.id}`, object);
  }

  public register(object: any, id: string): Promise<any> {
    return axios.post(`services/servicescard/api/public/customer/${id}`, object);
  }

  public getCustomerMinMaxPoints(): Promise<any> {
    return axios.get(this.endpointName + '/min-max-points');
  }

  public getAllCustomers(): Promise<any> {
    return axios.get(this.endpointName);
  }

  public validateCustomer(tenantId: string, customerId: string, email: string): Promise<any> {
    return axios.get(`services/servicescard/api/public/customer/${tenantId}/validate/${customerId}?email=${email}`);
  }
}
