import { BaseService } from '@/core/base-service';
import axios from 'axios';

export default class DashboardConfigurationService extends BaseService<any> {
  public constructor() {
    super('services/servicescard/api/dashboard-configuration');
  }

  public resortDashboardConfiguration(object): Promise<any> {
    return axios.put(this.endpointName + '/resort', object);
  }
}
