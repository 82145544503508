import axios from 'axios';
import { BaseService } from '@/core/base-service';

export default class QrCodeService extends BaseService<any> {
  public constructor() {
    super('services/servicescard/api/qr-code');
  }

  public create(object: any): Promise<any> {
    return axios.post(`services/servicescard/api/qr-code/create`, object);
  }

  public verify(object: any): Promise<any> {
    return axios.post(`services/servicescard/api/qr-code/verify`, object);
  }
}
