import { BaseService } from '@/core/base-service';
import axios from 'axios';

export default class PassInformationService extends BaseService<any> {
  public constructor() {
    super('services/servicescard/api/pass-information');
  }

  public create(object): Promise<any> {
    return axios.post('services/servicescard/api/pass-information', object);
  }

  public deleteById(passId: string): Promise<any> {
    return axios.delete(`services/servicescard/api/pass-information/${passId}`);
  }

  public getPassInformation(passInformation: string): Promise<any> {
    return axios.get(`services/servicescard/api/pass-information/${passInformation}`);
  }

  public redeemCoupon(passId: string): Promise<any> {
    return axios.put(`services/servicescard/api/pass-information/redeem/${passId}`);
  }

  public updateByCustomerId(customerId: string) {
    return axios.get(`services/servicescard/api/pass-information/update-by-customerid?customerId=${customerId}`);
  }

  public updateByTemplateId(templateId: string): Promise<any> {
    return axios.get(`services/servicescard/api/pass-information/update-by-templateid?templateId=${templateId}`);
  }

  public getInitCustomer(tenantId: string, customerId: string): Promise<any> {
    return axios.get(`services/servicescard/api/public/pass-information/init-customer/${tenantId}?customerId=${customerId}`);
  }

  public getByCustomerWithTenant(tenantId: string, customerId: string): Promise<any> {
    return axios.get(`services/servicescard/api/public/pass-information/${tenantId}?customerId=${customerId}`);
  }

  public send(customerId: string): Promise<any> {
    const body = {
      customerId: customerId,
    };
    return axios.post(this.endpointName + `/send`, body);
  }
}
