import { Module } from 'vuex';

export interface ProjectStateStorable {
  templateDevice: string;
}

function initTemplateDevice(): string {
  const templateDevice = localStorage.getItem('template-device');
  return templateDevice || 'apple';
}

export const defaultProjectState: ProjectStateStorable = {
  templateDevice: initTemplateDevice(),
};

export const projectStore: Module<ProjectStateStorable, any> = {
  state: { ...defaultProjectState },
  getters: {
    templateDevice: state => state.templateDevice,
  },
  mutations: {
    setTemplateDevice(state, templateDevice) {
      state.templateDevice = templateDevice;
      localStorage.setItem('template-device', templateDevice);
    },
  },
};
